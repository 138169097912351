import React from 'react';

const customIcons = {
  "upload": (
    <path fillRule="evenodd" clipRule="evenodd" d="M19 4C19.5523 4 20 4.44772 20 5V7C20 7.55228 19.5523 8 19 8C18.4477 8 18 7.55228 18 7V6H6V7C6 7.55228 5.55228 8 5 8C4.44772 8 4 7.55228 4 7V5C4 4.44772 4.44772 4 5 4H19ZM8 14C7.68524 14 7.38885 13.8518 7.2 13.6C6.86863 13.1582 6.95817 12.5314 7.4 12.2L11.4 9.2C11.7514 8.9432 12.2286 8.9432 12.58 9.2L16.58 12.02C17.0288 12.3384 17.1361 12.9596 16.82 13.41C16.5031 13.8623 15.8795 13.972 15.4272 13.6551L15.42 13.65L12.9984 11.9436C12.9995 11.9623 13 11.9811 13 12V20C13 20.5523 12.5523 21 12 21C11.4477 21 11 20.5523 11 20V12C11 11.9976 11 11.9953 11 11.9929L8.6 13.8C8.4269 13.9298 8.21637 14 8 14Z" fill="currentColor"/>
  ),
  "x-circle": (
    <path fillRule="evenodd" clipRule="evenodd" d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5229 6.47715 22 12 22C17.5229 22 22 17.5229 22 12C22 6.47715 17.5229 2 12 2ZM12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20ZM13.2977 9.28414C13.6898 8.89522 14.323 8.89782 14.7119 9.28994L14.7177 9.29574C15.1066 9.68786 15.104 10.321 14.7119 10.7099L13.4119 11.9999L14.7119 13.2899L14.7177 13.2957C15.1066 13.6879 15.104 14.321 14.7119 14.7099L14.7061 14.7157C14.314 15.1047 13.6808 15.1021 13.2919 14.7099L12.0019 13.4099L10.7119 14.7099L10.7061 14.7157C10.314 15.1047 9.68081 15.1021 9.29189 14.7099L9.28609 14.7041C8.89717 14.312 8.89977 13.6789 9.29189 13.2899L10.5919 11.9999L9.29189 10.7099C8.89977 10.3178 8.89977 9.68206 9.29189 9.28994C9.68401 8.89782 10.3198 8.89782 10.7119 9.28994L12.0019 10.5899L13.2919 9.28994L13.2977 9.28414Z" fill="currentColor"/>
  ),
  "minus-circle": (
    <path id="path" fillRule="evenodd" clipRule="evenodd" d="M16 2.667C8.635 2.667 2.665 8.636 2.665 16s5.97 13.333 13.333 13.333c7.364 0 13.334-5.97 13.334-13.333 0-7.364-5.97-13.334-13.334-13.334zm4 14.666h-8a1.333 1.333 0 0 1 0-2.666h8a1.333 1.333 0 1 1 0 2.666z" fill="currentColor"/>
  ),
  "plus-circle": (
    <path id="path" fillRule="evenodd" clipRule="evenodd" d="M16 2.667C8.635 2.667 2.665 8.636 2.665 16s5.97 13.333 13.333 13.333c7.364 0 13.334-5.97 13.334-13.333 0-7.364-5.97-13.334-13.334-13.334zm4 14.666h-2.667V20a1.333 1.333 0 0 1-2.667 0v-2.667h-2.667a1.333 1.333 0 0 1 0-2.666h2.667V12a1.333 1.333 0 0 1 2.667 0v2.666h2.666a1.333 1.333 0 1 1 0 2.667z" fill="currentColor"/>
  ),
  "percent": (
    <path fillRule="evenodd" clipRule="evenodd" d="M18.4814 4.25853C18.8293 3.91134 19.3928 3.91195 19.74 4.25989L19.74 4.25988L19.7414 4.26123C20.0886 4.60917 20.0879 5.17268 19.74 5.51988L5.52001 19.7399C5.35272 19.9075 5.1255 20.0016 4.88866 20.0012C4.39713 20.0005 3.99926 19.6014 4 19.1099L4 19.1099C4.00179 18.8741 4.095 18.6483 4.26 18.4799L18.48 4.25989L18.4814 4.25853ZM11.5 7.49989C11.5 9.43289 9.933 10.9999 8 10.9999C6.067 10.9999 4.5 9.43289 4.5 7.49989C4.5 5.56689 6.067 3.99989 8 3.99989C9.933 3.99989 11.5 5.56689 11.5 7.49989ZM9.5 7.49989C9.5 6.67146 8.82843 5.99989 8 5.99989C7.17157 5.99989 6.5 6.67146 6.5 7.49989C6.5 8.32831 7.17157 8.99989 8 8.99989C8.82843 8.99989 9.5 8.32831 9.5 7.49989ZM12.5 17.4999C12.5 15.5669 14.067 13.9999 16 13.9999C17.933 13.9999 19.5 15.5669 19.5 17.4999C19.5 19.4329 17.933 20.9999 16 20.9999C14.067 20.9999 12.5 19.4329 12.5 17.4999ZM14.5 17.4999C14.5 18.3283 15.1716 18.9999 16 18.9999C16.8284 18.9999 17.5 18.3283 17.5 17.4999C17.5 16.6715 16.8284 15.9999 16 15.9999C15.1716 15.9999 14.5 16.6715 14.5 17.4999Z" fill="currentColor"/>
  ),
  "eye-hide": (
    <path fillRule="evenodd" clipRule="evenodd" d="M21.87 11.5c-.64-1.11-4.16-6.68-10.14-6.5-5.53.14-8.73 5-9.6 6.5a1 1 0 0 0 0 1c.63 1.09 4 6.5 9.89 6.5h.25c5.53-.14 8.74-5 9.6-6.5a1 1 0 0 0 0-1zM12.22 17c-4.31.1-7.12-3.59-8-5 1-1.61 3.61-4.9 7.61-5 4.29-.11 7.11 3.59 8 5-1.03 1.61-3.61 4.9-7.61 5zM12 8.5a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7zm0 5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" fill="currentColor" />
  ),
  "eye-show": (
    <path fillRule="evenodd" clipRule="evenodd" d="M3.29 3.29a1.004 1.004 0 0 1 1.42 0l16 16 .006.006a1 1 0 0 1-.006 1.414l-.006.006a1 1 0 0 1-1.414-.006l-5.63-5.63a3.5 3.5 0 0 1-4.74-4.74L3.29 4.71a1.004 1.004 0 0 1 0-1.42zM10.5 12a1.5 1.5 0 0 0 1.5 1.5l.06-.01-1.56-1.56V12zm1.72 5c-4.3.1-7.12-3.59-8-5a13.7 13.7 0 0 1 2.24-2.72L5 7.87a15.891 15.891 0 0 0-2.87 3.63 1 1 0 0 0 0 1c.63 1.09 4 6.5 9.89 6.5h.25a9.479 9.479 0 0 0 3.23-.67l-1.58-1.58a7.734 7.734 0 0 1-1.7.25zm-.49-12c5.97-.18 9.5 5.39 10.14 6.5a1 1 0 0 1 .04 1A15.89 15.89 0 0 1 19 16.13l-1.51-1.41A13.702 13.702 0 0 0 19.78 12c-.89-1.41-3.71-5.11-8-5a7.74 7.74 0 0 0-1.7.25L8.5 5.67A9.48 9.48 0 0 1 11.73 5z" fill="currentColor"/>
  ),
  "checkmark": (
    <path fillRule="evenodd" clipRule="evenodd" d="M4.93 9a.5.5 0 0 1-.364-.16l-2.43-2.585a.5.5 0 0 1 .73-.685l2.06 2.195 4.205-4.6a.5.5 0 1 1 .74.67l-4.57 5A.5.5 0 0 1 4.936 9H4.93z" fill="#18A957"/>
  ),
  "x": (
    <path fillRule="evenodd" clipRule="evenodd" d="m6.706 6 2.165-2.145a.502.502 0 0 0-.71-.71L6 5.295l-2.145-2.15a.502.502 0 0 0-.71.71L5.296 6l-2.15 2.145a.5.5 0 0 0 0 .71.5.5 0 0 0 .707.003l.003-.003L6 6.705l2.145 2.15a.5.5 0 0 0 .707.003l.003-.003a.5.5 0 0 0 0-.71L6.706 6z" fill="#DF1670"/>
  )
}

const CustomIcon = ({iconName, className, width=24, height=24}) => {
  return    (
    <svg className={className} width={width} height={height} viewBox={`0 0 ${width} ${height}`} xmlns="http://www.w3.org/2000/svg">
      {customIcons[iconName]}
    </svg>
  )
}

export default CustomIcon
    import { render } from 'react-dom'                                    
    if (window.reactComponents === undefined) {                           
      window.reactComponents = {}                                         
    }                                                                     
    window.reactComponents.undefined = (function() {                   
    let _args = {}                                                        
                                                                          
    return {                                                              
      init: function (Args) {                                             
        _args = Args                                                      
      },                                                                  
      render: function () {                                               
        const  { id, ...props } = JSON.parse(_args)                       
        render(<undefined {...props}/>, document.getElementById(id))   
      }                                                                   
    }                                                                     
  }())