import React, {useEffect, useState} from 'react'
import CustomIcon from "../CustomIcon";

export default function PasswordField(props) {
  const {attrs, name, required, value} = props
  const [password, setPassword] = useState(value || "")
  const [maskPassword, setMaskPassword] = useState(true)
  const [inFocus, setInFocus] = useState(false)

  const {
    className,
    placeholder,
  } = attrs

  const displayPassword = (
    maskPassword
      ? '\u25CF'.repeat(password.length)
      : password
  )

  const updatePassword = (event) => {
    const valueFlat = event.target.value
    const newSubstringMatch = valueFlat.match(/[^\u25CF]+/)
    if (!newSubstringMatch) {
      setPassword(password.substring(0, valueFlat.length))
    } else {
      const newPassword = password.substring(0, newSubstringMatch.index) + newSubstringMatch[0]
      setPassword(newPassword)
    }
  }

  const hasEightCharacters = password.length >= 8
  const hasUppercase = /[A-Z]/.test(password)
  const hasLowercase = /[a-z]/.test(password)
  const hasNumber = /\d/.test(password)
  const hasSpecial = /[!"#$%&'()*+,-./:;<=>?@\[\\\]^_`{|}~]/.test(password)

  return (
    <div>
      <div className={"input-group appended-input col"}>
        <input name={name} className={className} inputMode="text" placeholder={placeholder}
         autoComplete="off" required={required} value={displayPassword} onChange={updatePassword}
         maxLength="64" onBlur={() => setInFocus(false)} onFocus={() => setInFocus(true)}
          />
          <input name={name} type={"hidden"} value={password} maxLength="64"/>
          <span className={"input-group-text"}>
            <a onClick={() => {setMaskPassword(!maskPassword)}} className={"text-primary"}>
              <CustomIcon iconName={maskPassword ? "eye-show" : "eye-hide"}/>
            </a>
          </span>
      </div>
      {inFocus &&
      <div className={"ml-1 mt-2 row"}>
        <div style={{fontSize: 12, fontWeight: 600}}>
          Password Requirements
        </div>
        <div style={{fontSize: 12, color: '#757575'}}>
          <div className="d-flex align-items-center"><CustomIcon width={12} height={12} iconName={hasEightCharacters ? "checkmark" : "x"} className={"me-2"}/><span>8 characters minimum</span></div>
          <div className="d-flex align-items-center"><CustomIcon width={12} height={12} iconName={hasUppercase ? "checkmark" : "x"} className={"me-2"}/><span>1 uppercase letter minimum</span></div>
          <div className="d-flex align-items-center"><CustomIcon width={12} height={12} iconName={hasLowercase ? "checkmark" : "x"} className={"me-2"}/><span>1 lowercase letter minimum</span></div>
          <div className="d-flex align-items-center"><CustomIcon width={12} height={12} iconName={hasNumber ? "checkmark" : "x"} className={"me-2"}/><span>1 number minimum</span></div>
          <div className="d-flex align-items-center"><CustomIcon width={12} height={12} iconName={hasSpecial ? "checkmark" : "x"} className={"me-2"}/><span>1 punctuation character minimum</span></div>
        </div>
      </div>
      }
    </div>



  )
}
    import { render } from 'react-dom'                                    
    if (window.reactComponents === undefined) {                           
      window.reactComponents = {}                                         
    }                                                                     
    window.reactComponents.PasswordField = (function() {                   
    let _args = {}                                                        
                                                                          
    return {                                                              
      init: function (Args) {                                             
        _args = Args                                                      
      },                                                                  
      render: function () {                                               
        const  { id, ...props } = JSON.parse(_args)                       
        render(<PasswordField {...props}/>, document.getElementById(id))   
      }                                                                   
    }                                                                     
  }())